export const modernActors = [
  { name: 'Adam Driver', id: 1023139 },
  { name: 'Adam Sandler', id: 19292 },
  { name: 'Al Pacino', id: 1158 },
  { name: 'Alan Rickman', id: 4566 },
  { name: 'Alicia Vikander', id: 227454 },
  { name: 'Amy Adams', id: 9273 },
  { name: 'Ana de Armas', id: 224513 },
  { name: 'Angela Bassett', id: 9780 },
  { name: 'Angelina Jolie', id: 11701 },
  { name: 'Anne Hathaway', id: 1813 },
  { name: 'Anthony Hopkins', id: 4173 },
  { name: 'Antonio Banderas', id: 3131 },
  { name: 'Arnold Schwarzenegger', id: 1100 },
  { name: 'Ben Affleck', id: 880 },
  { name: 'Ben Kingsley', id: 2282 },
  { name: 'Benicio del Toro', id: 1121 },
  { name: 'Benedict Cumberbatch', id: 71580 },
  { name: 'Bill Murray', id: 1532 },
  { name: 'Bill Paxton', id: 2053 },
  { name: 'Bill Pullman', id: 8984 },
  { name: 'Brad Pitt', id: 287 },
  { name: 'Bradley Cooper', id: 51329 },
  { name: 'Brendan Gleeson', id: 2039 },
  { name: 'Bruce Willis', id: 62 },
  { name: 'Cameron Diaz', id: 6941 },
  { name: 'Cate Blanchett', id: 112 },
  { name: 'Chadwick Boseman', id: 172069 },
  { name: 'Chiwetel Ejiofor', id: 5294 },
  { name: 'Chris Hemsworth', id: 74568 },
  { name: 'Chris Pratt', id: 73457 },
  { name: 'Chris Rock', id: 2632 },
  { name: 'Christian Bale', id: 3894 },
  { name: 'Christoph Waltz', id: 27319 },
  { name: 'Clint Eastwood', id: 190 },
  { name: 'Colin Farrell', id: 72466 },
  { name: 'Cuba Gooding Jr.', id: 9777 },
  { name: 'Daniel Craig', id: 8784 },
  { name: 'Daniel Day-Lewis', id: 11856 },
  { name: 'Danny DeVito', id: 518 },
  { name: 'Danny Glover', id: 2047 },
  { name: 'Dennis Hopper', id: 2778 },
  { name: 'Denzel Washington', id: 5292 },
  { name: 'Dev Patel', id: 76788 },
  { name: 'Diane Keaton', id: 3092 },
  { name: 'Don Cheadle', id: 1896 },
  { name: 'Dustin Hoffman', id: 4483 },
  { name: 'Dwayne Johnson', id: 18918 },
  { name: 'Eddie Murphy', id: 776 },
  { name: 'Edward Norton', id: 819 },
  { name: 'Emma Stone', id: 54693 },
  { name: 'Emma Thompson', id: 7056 },
  { name: 'Ethan Hawke', id: 569 },
  { name: 'Evan Peters', id: 55089 },
  { name: 'Florence Pugh', id: 1373737 },
  { name: 'Frances McDormand', id: 3910 },
  { name: 'Gary Oldman', id: 64 },
  { name: 'Gene Hackman', id: 193 },
  { name: 'George Clooney', id: 1461 },
  { name: 'Glenn Close', id: 515 },
  { name: 'Halle Berry', id: 4587 },
  { name: 'Harrison Ford', id: 3 },
  { name: 'Harvey Keitel', id: 1037 },
  { name: 'Heath Ledger', id: 1810 },
  { name: 'Helen Mirren', id: 15735 },
  { name: 'Hugh Grant', id: 3291 },
  { name: 'Idris Elba', id: 17605 },
  { name: 'J.K. Simmons', id: 18999 },
  { name: 'Jack Black', id: 70851 },
  { name: 'Jack Nicholson', id: 514 },
  { name: 'Jackie Chan', id: 18897 },
  { name: 'James Caan', id: 3085 },
  { name: 'Jamie Foxx', id: 134 },
  { name: 'Jane Fonda', id: 6352 },
  { name: 'Jason Statham', id: 976 },
  { name: 'Javier Bardem', id: 3810 },
  { name: 'Jeff Bridges', id: 1229 },
  { name: 'Jeff Goldblum', id: 4785 },
  { name: 'Jennifer Connelly', id: 6161 },
  { name: 'Jennifer Lawrence', id: 72129 },
  { name: 'Jesse Plemons', id: 88124 },
  { name: 'Jim Carrey', id: 206 },
  { name: 'Julia Roberts', id: 1204 },
  { name: 'Julianne Moore', id: 1231 },
  { name: 'Joaquin Phoenix', id: 73421 },
  { name: 'Jodie Foster', id: 1038 },
  { name: 'Joe Pesci', id: 4517 },
  { name: 'John Candy', id: 7180 },
  { name: 'John Cusack', id: 3036 },
  { name: 'John Malkovich', id: 6949 },
  { name: 'John Travolta', id: 8891 },
  { name: 'Jonah Hill', id: 21007 },
  { name: 'Josh Brolin', id: 16851 },
  { name: 'Judi Dench', id: 5309 },
  { name: 'Julia Roberts', id: 509 },
  { name: 'Kate Winslet', id: 204 },
  { name: 'Keanu Reeves', id: 6384 },
  { name: 'Kenneth Branagh', id: 11181 },
  { name: 'Kevin Bacon', id: 4724 },
  { name: 'Kevin Costner', id: 1269 },
  { name: 'Kirsten Dunst', id: 205 },
  { name: 'Kristen Stewart', id: 37917 },
  { name: 'Laura Dern', id: 4784 },
  { name: 'Laurence Fishburne', id: 2975 },
  { name: 'Léa Seydoux', id: 121529 },
  { name: 'Leonardo DiCaprio', id: 6193 },
  { name: 'Liam Neeson', id: 3896 },
  { name: "Lupita Nyong'o", id: 1267329 },
  { name: 'Margot Robbie', id: 234352 },
  { name: 'Martin Sheen', id: 8349 },
  { name: 'Matt Damon', id: 1892 },
  { name: 'Matthew McConaughey', id: 10297 },
  { name: 'Meryl Streep', id: 5064 },
  { name: 'Mia Goth', id: 1137824 },
  { name: 'Michael B. Jordan', id: 135651 },
  { name: 'Michael Caine', id: 3895 },
  { name: 'Michael Douglas', id: 3392 },
  { name: 'Michael Fassbender', id: 17288 },
  { name: 'Michael Keaton', id: 2232 },
  { name: 'Michelle Pfeiffer', id: 1160 },
  { name: 'Michelle Yeoh', id: 1620 },
  { name: 'Morgan Freeman', id: 192 },
  { name: 'Nicolas Cage', id: 2963 },
  { name: 'Nicole Kidman', id: 2227 },
  { name: 'Octavia Spencer', id: 6944 },
  { name: 'Oscar Isaac', id: 25072 },
  { name: 'Owen Wilson', id: 887 },
  { name: 'Paul Dano', id: 17142 },
  { name: 'Paul Giamatti', id: 13242 },
  { name: 'Paul Rudd', id: 22226 },
  { name: 'Penélope Cruz', id: 955 },
  { name: 'Philip Seymour Hoffman', id: 1233 },
  { name: 'Pierce Brosnan', id: 517 },
  { name: 'Regina King', id: 9788 },
  { name: 'Richard Gere', id: 1205 },
  { name: 'Robert De Niro', id: 380 },
  { name: 'Robert Downey Jr.', id: 3223 },
  { name: 'Robert Pattinson', id: 11288 },
  { name: 'Ryan Gosling', id: 30614 },
  { name: 'Salma Hayek', id: 3136 },
  { name: 'Samuel L. Jackson', id: 2231 },
  { name: 'Saoirse Ronan', id: 36592 },
  { name: 'Sean Connery', id: 738 },
  { name: 'Sharon Stone', id: 4430 },
  { name: 'Sigourney Weaver', id: 10205 },
  { name: 'Steve Buscemi', id: 884 },
  { name: 'Steve Martin', id: 67773 },
  { name: 'Susan Sarandon', id: 4038 },
  { name: 'Sylvester Stallone', id: 16483 },
  { name: 'Tilda Swinton', id: 3063 },
  { name: 'Timothée Chalamet', id: 1190668 },
  { name: 'Tom Cruise', id: 500 },
  { name: 'Tom Hanks', id: 31 },
  { name: 'Tom Hardy', id: 2524 },
  { name: 'Tom Holland', id: 1136406 },
  { name: 'Tommy Lee Jones', id: 2176 },
  { name: 'Uma Thurman', id: 139 },
  { name: 'Val Kilmer', id: 5576 },
  { name: 'Vin Diesel', id: 12835 },
  { name: 'Viola Davis', id: 19492 },
  { name: 'Warren Beatty', id: 6449 },
  { name: 'Wesley Snipes', id: 10814 },
  { name: 'Whoopi Goldberg', id: 2395 },
  { name: 'Willem Dafoe', id: 5293 },
  { name: 'Winona Ryder', id: 1920 },
  { name: 'Woody Harrelson', id: 57755 },
  { name: 'Zoe Saldaña', id: 8691 },
];

export const classicActors = [
  { name: 'Audrey Hepburn', id: 1932 },
  { name: 'Ava Gardner', id: 25787 },
  { name: 'Barbara Stanwyck', id: 14974 },
  { name: 'Bette Davis', id: 3380 },
  { name: 'Burt Lancaster', id: 13784 },
  { name: 'Cary Grant', id: 2638 },
  { name: 'Clark Gable', id: 11492 },
  { name: 'Elizabeth Taylor', id: 3635 },
  { name: 'Errol Flynn', id: 8724 },
  { name: 'Frank Sinatra', id: 4347 },
  { name: 'Fred Astaire', id: 30181 },
  { name: 'Gary Cooper', id: 4068 },
  { name: 'Gene Kelly', id: 13294 },
  { name: 'Grace Kelly', id: 4070 },
  { name: 'Gregory Peck', id: 8487 },
  { name: 'Greta Garbo', id: 19549 },
  { name: 'Henry Fonda', id: 4958 },
  { name: 'Humphrey Bogart', id: 4110 },
  { name: 'Ingrid Bergman', id: 4111 },
  { name: 'Jack Lemmon', id: 3151 },
  { name: 'James Cagney', id: 5788 },
  { name: 'James Dean', id: 2749 },
  { name: 'James Stewart', id: 854 },
  { name: 'Joan Crawford', id: 31550 },
  { name: 'John Wayne', id: 4165 },
  { name: 'Judy Garland', id: 9066 },
  { name: 'Katharine Hepburn', id: 6598 },
  { name: 'Kirk Douglas', id: 2090 },
  { name: 'Lauren Bacall', id: 7570 },
  { name: 'Laurence Olivier', id: 3359 },
  { name: 'Marilyn Monroe', id: 3149 },
  { name: 'Marlene Dietrich', id: 2896 },
  { name: 'Marlon Brando', id: 3084 },
  { name: 'Mickey Rooney', id: 1937 },
  { name: 'Montgomery Clift', id: 12151 },
  { name: 'Orson Welles', id: 40 },
  { name: 'Paul Newman', id: 3636 },
  { name: 'Rita Hayworth', id: 33741 },
  { name: 'Robert Redford', id: 4135 },
  { name: 'Rock Hudson', id: 18735 },
  { name: 'Sammy Davis Jr.', id: 20156 },
  { name: 'Sidney Poitier', id: 16897 },
  { name: 'Tony Curtis', id: 3150 },
  { name: 'Walter Matthau', id: 6837 },
];
